<template>
  <div class="manage">
    <Banner title="物业管理" />
    <ManageFunction />
    <ManageServer />
    <ManageCloud />
    <ManageUse />
  </div>
</template>

<script>
import Banner from '../../src/components/banner/Banner'
import ManageFunction from '../../src/components/manage/ManageFunction.vue'
import ManageServer from '../components/manage/ManageServer.vue'
import ManageCloud from '../components/manage/ManageCloud.vue'
import ManageUse from '../components/manage/ManageUse.vue'

export default {
  name: "Manage",
  components: {
    Banner,
    ManageFunction,
    ManageServer,
    ManageCloud,
    ManageUse
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 992px) {
}
@media screen and (max-width: 992px) {
}
</style>
