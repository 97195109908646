<template>
  <div class="banner">
    <img src="../../assets/index/banner.jpg" alt="" />
    <div class="content">
      <h1>{{title}}</h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .banner {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .content {
      width: 72rem;
      height: 20rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -10rem;
      margin-left: -36rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        color: #fff;
        font-size: 3rem;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .banner {
    width: 100%;
    height: 50vw;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .content {
      width: 90vw;
      height: 20vw;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -10vw;
      margin-left: -45vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        color: #fff;
        font-size: 4.8vw;
        // padding-left: 20vw ;
      }
    }
  }
}
</style>