<template>
  <div class="SCRM">
    <div class="content_box">
      <div class="info">
        <div class="qkl_tips">运营提升</div>
        <div class="qkl_title">物业SCRM功能</div>
        <div class="qkl_p">精细化管理运营业主资源 提升服务质量</div>
      </div>
      <div class="list_box">
        <div class="list" v-for="(item,index) in list" :key="index">
          <div class="img"><img :src="item.img" alt=""></div>
          <div class="title">{{item.title}}</div>
          <div class="qkl_p">{{item.text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list:[
        {img: require('../../assets/SCRM/1.jpg'),title:'业主管理',text:'基于精细化的业主画像，通过群 朋友圈 群发等方式对业主进行营销推送'},
        {img: require('../../assets/SCRM/2.jpg'),title:'物业SCRM',text:'对业主进行精细化的标签画像，提高服务，拓展更多商业模式'},
        {img: require('../../assets/SCRM/3.jpg'),title:'移动办公',text:'物业内部管理涉及的请假 报销 审批 汇报 文件管理等应用'},
        {img: require('../../assets/SCRM/4.jpg'),title:'舆情监控',text:'监控员工与业主 业主微信群的聊天记录，减少物业服务纠纷'}
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .SCRM{
    padding: 4rem 0;
    .content_box{
      width: 72rem;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .info{
        align-items: center;
        display: flex;
        flex-direction: column;
        .qkl_title{
          margin-top: 0.5rem;
        }
        .qkl_p{
          margin-top: 1.5rem;
        }
      }
      .list_box{
        display: flex;
        flex-wrap: wrap;
        margin-top: 4rem;
        .list{
          width: 17rem;
          margin: 0 0.5rem;
          padding: 0 1rem;
          // background: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          .img{
            width: 17rem;
            height: 24rem;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .title{
            color: #202020;
            margin: 1rem 0 0.5rem;
          }
          .qkl_p{
            font-size: 0.8rem;
            height: 5rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .SCRM{
    padding: 8vw 0;
    .content_box{
      width: 90vw;
      margin: 0 auto;
      .info{
        align-items: center;
        display: flex;
        flex-direction: column;
        .qkl_title{
          margin-top: 1vw;
        }
        .qkl_p{
          margin-top: 3vw;
        }
      }
      .list_box{
        display: flex;
        flex-wrap: wrap;
        margin-top: 8vw;
        .list{
          width: 43vw;
          margin: 0 1vw;
          padding: 0 1vw;
          // background: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          .img{
            width: 43vw;
            height: 60vw;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .title{
            color: #202020;
            margin: 4vw 0 2vw;
          }
          .qkl_p{
            font-size: 3.6vw;
            text-align: justify;
            height: 24vw;
          }
        }
      }
    }
  }
}
</style>
