<template>
  <div class="bottom">
    <div class="box">
      <!-- <div class="title">多一份选择，总有收获</div> -->
      <div class="content">联系方式：</div>
      <div class="info info_first">电话: 19107779630</div>
      <div class="info">邮编: 535000</div>
      <div class="info">地址: 广西钦州市永福东大街19号大华富贵世家1-121商铺</div>
      <div class="copy">
        版权所有 广西巧科粒智能科技有限公司 Copyright © 2021-{{(new Date).getFullYear()}} 桂ICP备2021005193号-1 Guangxi Qiaokeli Intelligence Technology Co., Ltd
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .bottom {
    background: #5e616d;
    color: #fff;
    .box {
      width: 72rem;
      margin: 0 auto;
      position: relative;
      padding: 2rem 0 2rem;
      .title {
        font-size: 2rem;
      }
      .content {
        margin-top: 1.6rem;
        font-size: 1.3rem;
      }
      .info {
        margin-top: 0.8rem;
        // font-size: 1.2rem;
        &.info_first {
          margin-top: 1.2rem;
        }
      }
      .copy {
        margin-top: 1.6rem;
        font-size: 1rem;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .bottom {
    background: #5e616d;
    color: #fff;
    padding: 4vw 0;
    .box {
      width: 90vw;
      margin: 0 auto;
      .title {
        font-size: 4vw;
        text-align: center;
      }
      .content {
        // margin-top: 2vw;
        font-size: 4vw;
        text-align: center;
        margin-bottom: 8vw;
      }
      .info {
        margin-top: 4vw;
        font-size: 3.2vw;
      }
      .copy {
        margin-top: 4vw;
        font-size: 2.4vw;
      }
    }
  }
}
</style>
