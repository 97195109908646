<template>
  <div class="manageUse">
    <div class="content_box">
      <div class="info">
        <div class="qkl_tips">智能 管理 沉淀 运营</div>
        <div class="qkl_title">系统应用场景</div>
        <div class="qkl_p">智慧社区建设 物业管理 智慧街道管理</div>
      </div>
      <div class="list_box">
        <div class="list" v-for="(item,index) in list" :key="index">
          <div class="title">{{item.title}}</div>
          <div class="qkl_p">{{item.text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list:[
        {title:'智慧社区建设',text:'通过人脸门禁 智能摄像头 智能快递柜 充电桩 停车系统等设备提升社区的智能化'},
        {title:'多社区物业管理',text:'涵盖物业缴费 投诉建议 报修巡检 业主交流 活动调查 水电费收缴等各项物业管理功能'},
        {title:'业主SCRM运营',text:'对社区业主进行标签画像，精细化运营管理业主资源，为社区商业拓展更多运营模式'},
        {title:'智慧街道管理',text:'为街道、社区，提供民生管理、智慧党建、综治管理、网格化管理等全场景解决方案'}
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .manageUse{
    padding: 4rem 0;
    .content_box{
      width: 72rem;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .info{
        align-items: center;
        display: flex;
        flex-direction: column;
        .qkl_title{
          margin-top: 0.5rem;
        }
        .qkl_p{
          margin-top: 1.5rem;
        }
      }
      .list_box{
        display: flex;
        flex-wrap: wrap;
        margin-top: 2rem;
        .list{
          width: 16rem;
          margin: 0 1rem;
          padding: 2rem 2.5rem;
          box-shadow: 0 0.5rem 0.5rem 0.1rem #eee;
          // background: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          .icon{
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4rem;
            background: #1072d5;
            i{
              font-size: 2rem;
              color: #fff;
            }
          }
          .title{
            color: #1072d5;
            font-size: 1.2rem;
            margin: 0.5rem 0;
          }
          .qkl_p{
            font-size: 0.8rem;
            margin-top: 1rem;
            line-height: 2;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .manageUse{
    padding: 8vw 0;
    .content_box{
      width: 90vw;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .info{
        align-items: center;
        display: flex;
        flex-direction: column;
        .qkl_title{
          margin-top: 1vw;
        }
        .qkl_p{
          margin-top: 3vw;
        }
      }
      .list_box{
        display: flex;
        flex-wrap: wrap;
        margin-top: 4vw;
        .list{
          width: 41vw;
          margin: 0 2vw;
          padding: 4vw 2vw;
          box-shadow: 0 1vw 1vw 0.2vw #eee;
          // background: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          
          .title{
            color: #1072d5;
            font-size: 1.2rem;
            margin: 0.5rem 0;
          }
          .qkl_p{
            font-size: 0.8rem;
            margin-top: 1rem;
            line-height: 2;
          }
        }
      }
    }
  }
}
</style>
