<template>
  <div class="manageServer">
    <div class="content_box">
      <div class="img">
        <img src="../../assets/manage/serve.jpg" alt="">
      </div>
      <div class="list_box">
        <div class="list" v-for="(item,index) in list" :key="index">
          <div class="title">{{item.val}}</div>
          <div class="qkl_p">{{item.title}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list:[
        {title:'社区客户',val: 9364},
        {title:'硬件集成',val: 20},
        {title:'年研发',val: 10},
        {title:'天内迭代优化',val: 7}
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .manageServer{
    padding: 4rem 0;
    .content_box{
      width: 72rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 0.2rem 0.5rem 0.2rem #eee;
      .img{
        width: 12rem;
        height: 8rem;
        text-align: justify;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .list_box{
        width: 60rem;
        display: flex;
        .list{
          width: 15rem;
          height: 8rem;
          // background: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          .title{
            color: #202020;
            margin-top: 0.5rem;
            font-size: 2rem;
            color: #1072d5;
          }
          .qkl_p{
            font-size: 0.8rem;
            color: #202020;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .manageServer{
    padding: 8vw 0;
    .content_box{
      width: 90vw;
      margin: 0 auto;
      box-shadow: 0 0.2rem 0.5rem 0.2rem #eee;
      .img{
        width: 90vw;
        height: 60vw;
        text-align: justify;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .list_box{
        width: 90vw;
        display: flex;
        flex-wrap: wrap;
        .list{
          width: 45vw;
          height: 30vw;
          // background: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          .title{
            color: #202020;
            margin-top: 2vw;
            font-size: 8vw;
            color: #1072d5;
          }
          .qkl_p{
            font-size: 3.2vw;
            color: #202020;
          }
        }
      }
    }
  }
}
</style>
