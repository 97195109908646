<template>
  <div class="manageCloud">
    <div class="content_box">
      <div class="img">
        <img src="../../assets/manage/cloud.png" alt="">
      </div>
      <div class="info">
        <div class="qkl_tips">提高效率</div>
        <div class="qkl_title">软硬件一体化智慧物业管理系统平台</div>
        <div class="qkl_p">智慧物业管理系统集云计算、大数据、物联网、人工智能等新技术于一体，联动软硬件设施，实现物业管理便捷化、智能化、数字化</div>
        <div class="qkl_p">系统统筹整理各类资源，有效提高物业服务质量效率和监管水平，最大程度、最低成本的满足业主多元化需求，使服务更精准、管理更智能</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .manageCloud{
    padding: 4rem 0;
    .content_box{
      width: 72rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .img{
        width: 36rem;
        display: flex;
        flex-wrap: wrap;
        img{
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }
      .info{
        width: 36rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: justify;
        .qkl_title{
          margin-top: 0.5rem;
        }
        .qkl_p{
          margin-top: 1.5rem;
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .manageCloud{
    padding: 8vw 0;
    .content_box{
      width: 90vw;
      margin: 0 auto;
      .img{
        width: 90vw;
        display: flex;
        flex-wrap: wrap;
        img{
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }
      .info{
        width: 90vw;
        margin-top: 4vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: justify;
        .qkl_title{
          margin-top: 1vw;
        }
        .qkl_p{
          margin-top: 4vw;
        }
      }
    }
  }
}
</style>
