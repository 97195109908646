<template>
  <div class="manageFunction">
    <div class="content_box">
      <div class="info">
        <div class="qkl_tips">智慧物业</div>
        <div class="qkl_title">物业管理功能</div>
        <div class="qkl_p">大部分物业仅能满足基本的物业管理项目，例如物业缴费、门禁管理等，没有能力完善社区服务和提高社区生活质量,新型物业致力于打造高效、安全、智能又容易商业变现的物业服务系统</div>
      </div>
      <div class="list_box">
        <div class="list" v-for="(item,index) in list" :key="index">
          <div class="icon"><i :class="item.icon"></i></div>
          <div class="qkl_p title">{{item.title}}</div>
          <div class="qkl_p p">{{item.text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list:[
        {icon:'el-icon-s-finance',title:'在线缴费',text:'业主在社区平台查询相关费用后即可在线缴费'},
        {icon:'el-icon-s-promotion',title:'智慧巡检',text:'实现巡检工作的无纸化 智能化，提高巡检工作效率'},
        {icon:'el-icon-message-solid',title:'消息通知',text:'通过短信 微信群发 朋友圈等高效率的方式发送通知'},
        {icon:'el-icon-s-data',title:'远程抄表',text:'减少物业管理工作量，提高水电燃气的抄表效率'},
        {icon:'el-icon-s-opportunity',title:'访客通行',text:'业主只需分享有时效的开门码访客即可开门 '},
        {icon:'el-icon-s-claim',title:'快递代收',text:'物业代收快递，业主查询信息后可收取快递'},
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .manageFunction{
    padding: 4rem 0;
    .content_box{
      width: 72rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .info{
        width: 24rem;
        padding-right: 2rem;
        text-align: justify;
        .qkl_title{
          margin-top: 0.5rem;
        }
        .qkl_p{
          margin-top: 1rem;
        }
      }
      .list_box{
        width: 48rem;
        display: flex;
        flex-wrap: wrap;
        .list{
          width: 15rem;
          height: 12rem;
          // background: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 0 2rem;
          text-align: center;
          margin-left: 1rem;
          margin-top: 1rem;
          &:nth-child(3n-2){
            margin-left: 0;
          }
          &:nth-child(-n+3){
            margin-top: 0;
          }
          .icon{
            width: 3.2rem;
            height: 3.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4rem;
            background: #1072d5;
            i{
              font-size: 2rem;
              color: #fff;
            }
          }
          .title{
            color: #202020;
            margin: 0.5rem 0;
          }
          .p{
            height: 4rem;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .manageFunction{
    padding: 8vw 0;
    .content_box{
      width: 90vw;
      margin: 0 auto;
      align-items: center;
      .info{
        width: 100%;
        margin-top: 4vw;
        .qkl_title{
          margin-top: 1vw;
        }
        .qkl_p{
          margin-top: 2vw;
        }
      }
      .list_box{
        width: 90vw;
        display: flex;
        flex-wrap: wrap;
        margin-top: 8vw;
        .list{
          width: 45vw;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 0 4vw;
          text-align: center;
          margin-top: 8vw;
          &:nth-child(-n+2){
            margin-top: 0;
          }
          .icon{
            width: 12vw;
            height: 12vw;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12vw;
            background: #1072d5;
            i{
              font-size: 8vw;
              color: #fff;
            }
          }
          .title{
            color: #202020;
            margin: 2vw 0;
          }
          .p{
            height: 16vw;
            margin-top: 1vw;
          }
        }
      }
    }
  }
}
</style>
