<template>
  <div class="business">
    <div class="content_box">
      <div class="info">
        <div class="qkl_tips">商业变现</div>
        <div class="qkl_title">社区商业功能</div>
        <div class="qkl_p">助力社区运营和物业拓展更多的商业模式</div>
        <el-carousel :interval="5000" class="swiper" height="16rem" indicator-position="outside" type="card" loop>
          <el-carousel-item v-for="(item,index) in list" :key="index" class="item">
            <!-- <div class="icon"><i class="el-icon-share"></i></div> -->
            <div class="p">{{item.p}}</div>
            <div class="list_info">
              <div class="list_icon"><i :class="item.icon"></i></div>
              <div class="list_info_content">
                <div class="title">{{item.title}}</div>
                <div class="text">{{item.text}}</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <el-carousel :interval="5000" class="swiper_phone" height="40vw" indicator-position="outside" loop>
          <el-carousel-item v-for="(item,index) in list" :key="index" class="item">
            <!-- <div class="icon"><i class="el-icon-share"></i></div> -->
            <div class="p">{{item.p}}</div>
            <div class="list_info">
              <div class="list_icon"><i :class="item.icon"></i></div>
              <div class="list_info_content">
                <div class="title">{{item.title}}</div>
                <div class="text">{{item.text}}</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list:[
          {p:'物业APP或小程序里内置线上商城', icon:'el-icon-s-goods', title:'商城', text: '线上商城'},
          {p:'针对社区业主做外卖服务赚取佣金', icon:'el-icon-s-shop', title:'外卖系统', text: '整合商家提供外卖服务'},
          {p:'整合周边商家针对业主服务，物业可自营', icon:'el-icon-s-platform', title:'多商家管理', text: '商家入驻平台'},
          {p:'以社区为业主消费群体，业主组团拼团', icon:'el-icon-s-home', title:'社区拼团', text: '以社区为中心的新商业模式'}
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .business{
    padding: 4rem 0;
    .content_box{
      width: 72rem;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .info{
        align-items: center;
        display: flex;
        flex-direction: column;
        .qkl_title{
          margin-top: 0.5rem;
        }
        .qkl_p{
          margin-top: 1.5rem;
        }
        .swiper_phone{
          display: none;
        }
        .swiper{
          width: 72rem;
          margin-top: 2rem;
          .item{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // padding: 2rem 4rem;
            background: #eee;
              .icon{
                width: 2.4rem;
                height: 2.4rem;
                border-radius: 3rem;
                margin-left: 12rem;
                color: #fff;
                background: #1072d5;
                display: flex;
                justify-content: center;
                align-items: center;
                i{
                  font-size: 1.5rem;
                }
              }
              .p{
                font-size: 1rem;
                margin-top: 1rem;
              }
              .list_info{
                display: flex;
                align-items: center;
                margin-top: 1rem;
                height: 4rem;
                .list_icon{
                  width: 4rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  i{
                    font-size: 2rem;
                    color: #1072d5;
                  }
                }
                .list_info_content{
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  .title{
                    font-size: 0.75rem;
                  }
                  .text{
                    font-size: 0.8rem;
                    color: #1072d5;
                  }
                }
              }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .business{
    padding: 8vw 0;
    .content_box{
      width: 90vw;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .info{
        align-items: center;
        display: flex;
        flex-direction: column;
        .qkl_title{
          margin-top: 1vw;
        }
        .qkl_p{
          margin-top: 3vw;
        }
        .swiper{
          display: none;
        }
        .swiper_phone{
          width: 90vw;
          margin-top: 4vw;
          .item{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
              .icon{
                width: 4vw;
                height: 4vw;
                border-radius: 4vw;
                // margin-left: 16vw;
                color: #fff;
                background: #1072d5;
                display: flex;
                justify-content: center;
                align-items: center;
                i{
                  font-size: 3vw;
                }
              }
              .p{
                font-size: 4vw;
                // margin-top: 1rem;
              }
              .list_info{
                display: flex;
                align-items: center;
                margin-top: 2vw;
                height: 16vw;
                width: 100%;
                padding: 0 8vw;
                .list_icon{
                  width: 16vw;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  i{
                    font-size: 12vw;
                    color: #1072d5;
                  }
                }
                .list_info_content{
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  .title{
                    font-size: 3.2vw;
                  }
                  .text{
                    font-size: 4vw;
                    color: #1072d5;
                  }
                }
              }
          }
        }
      }
    }
  }
}
</style>
