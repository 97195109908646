import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Manage from '../views/Manage.vue'
import SCRM from '../views/SCRM.vue'
import Intelligence from '../views/Intelligence.vue'
import Hardware from '../views/Hardware.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/manage',
    name: 'Manage',
    component: Manage
  },
  {
    path: '/scrm',
    name: 'SCRM',
    component: SCRM
  },
  {
    path: '/intelligence',
    name: 'Intelligence',
    component: Intelligence
  },
  {
    path: '/hardware',
    name: 'Hardware',
    component: Hardware
  },
  

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
