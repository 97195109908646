<template>
  <div class="business">
    <div class="content_box">
      <div class="info">
        <div class="qkl_tips">功能概述</div>
        <div class="qkl_title">智慧社区功能模块</div>
        <div class="qkl_p">智能硬件 智慧街道 智慧社区 物业管理</div>
      </div>
      <div class="list_box">
        <div class="list" v-for="(item,index) in list" :key="index">
          <div class="icon"><i :class="item.icon"></i></div>
          <div class="qkl_p title">{{item.title}}</div>
          <div class="text_box">
            <div class="qkl_p text" v-for="(text,i) in item.text" :key="i">
              <i class="el-icon-circle-check"></i>
              {{text}}
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list:[
        {icon:'el-icon-s-tools', title:'商城', text: ['刷脸大门门禁','智能停车系统','智能快递柜','人脸识别摄像头','智能充电桩']},
        {icon:'el-icon-s-grid', title:'智慧街道', text: ['网格化管理','智慧党建','综合治理','群众留言建议','志愿者活动','群众投票建议']},
        {icon:'el-icon-s-custom', title:'智慧社区', text: ['人脸识别','物料网建设','智慧大屏','智慧安防','智慧巡检','人员监控']},
        {icon:'el-icon-s-help', title:'物业管理', text: ['多社区管理','多物业公司','多城市管理','数十项物业功能','在线缴费','移动办公']}
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .business{
    padding: 4rem 0;
    .content_box{
      width: 72rem;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .info{
        align-items: center;
        display: flex;
        flex-direction: column;
        .qkl_title{
          margin-top: 0.5rem;
        }
        .qkl_p{
          margin-top: 1.5rem;
        }
      }
      .list_box{
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-between;
        width: 72rem;
        margin-top: 2rem;
        .list{
          width: 16rem;
          height: 20rem;
          // background: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 0 2rem;
          text-align: center;
          .icon{
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4rem;
            background: #1072d5;
            i{
              font-size: 2rem;
              color: #fff;
            }
          }
          .title{
            color: #202020;
            margin: 0.5rem 0;
          }
          .text_box{
            text-align: left;
            padding-left: 1.5rem;
            height: 12rem;
            margin-top: 1rem;
            .text{
              line-height: 2;
              i{
                font-size: 1rem;
                color: #1072d5;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .business{
    padding: 8vw 0;
    .content_box{
      width: 90vw;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .info{
        align-items: center;
        display: flex;
        flex-direction: column;
        .qkl_title{
          margin-top: 1vw;
        }
        .qkl_p{
          margin-top: 3vw;
        }
      }
      .list_box{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 90vw;
        margin-top: 4vw;
        .list{
          width: 45vw;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 4vw 4vw;
          text-align: center;
          .icon{
            width: 16vw;
            height: 16vw;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 16vw;
            background: #1072d5;
            i{
              font-size: 8vw;
              color: #fff;
            }
          }
          .title{
            color: #202020;
            margin: 1vw 0;
          }
          .text_box{
            margin-top: 2vw;
            line-height: 1.5;
            text-align: left;
            height: 40vw;
            .text{
              i{
                color: #1072d5;
              }
            }
          }
        }
      }
    }
  }
}
</style>
