<template>
  <div class="home">
    <div class="banner">
      <img src="../assets/index/banner.jpg" alt="" />
      <div class="content">
        <h1>高端智能的<br>智慧社区和物业管理系统</h1>
        <p>功能丰富持续升级，性能稳定操作简便，源码开放，支持二开</p>
      </div>
    </div>
    <ManageFunction />
    <ManageServer />
    <ManageCloud />
    <SCRM />
    <Business />
    <Intelligen />
    <Hard />
    <ManageUse />
  </div>
</template>

<script>
import ManageFunction from "../../src/components/manage/ManageFunction"
import ManageServer from "../../src/components/manage/ManageServer"
import ManageCloud from "../../src/components/manage/ManageCloud"
import SCRM from "../../src/components/SCRM/SCRM"
import Business from "../../src/components/intelligence/Business"
import Intelligen from "../../src/components/intelligence/Intelligen"
import Hard from "../../src/components/hardware/Hard"
import ManageUse from '../components/manage/ManageUse'

export default {
  name: "Home",
  components: {
    ManageFunction,
    ManageServer,
    ManageCloud,
    SCRM,
    Business,
    Intelligen,
    Hard,
    ManageUse
  },
  data() {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .home {
    padding-bottom: 10rem;
    .banner {
      width: 100%;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .content{
        width: 72rem;
        height: 20rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -10rem;
        margin-left: -36rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1{
          color: #fff;
          font-size: 3rem;
        }
        p{
          margin-top: 1rem;
          color: #ccc;
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .home {
    .banner {
      width: 100%;
      height: 50vw;
      position: relative;
      img {
        width: 100%;height: 100%;
        object-fit: cover;
      }
      .content{
        width: 90vw;
        height: 20vw;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -10vw;
        margin-left: -45vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1{
          color: #fff;
          font-size: 4.8vw;
        }
        p{
          width: 50vw;
          margin-top: 2vw;
          color: #ccc;
          font-size: 2vw;
        }
      }
    }
  }
}
</style>
