<template>
  <div class="business">
    <div class="content_box">
      <div class="info">
        <div class="qkl_tips">安全 智能 高效</div>
        <div class="qkl_title">智慧社区硬件支持</div>
        <div class="qkl_p">通过各类硬件的互联互通打破小区信息的孤岛，从而掌握小区全数据</div>
      </div>
      <div class="list_box">
        <div class="list" v-for="(item,index) in list" :key="index">
          <div class="img"><img :src="item.img" alt=""></div>
          <div class="qkl_p title">{{item.title}}</div>
          <div class="qkl_p text">{{item.text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list:[
        {img: require('../../assets/hardware/1.png'),title:'智慧停车',text:'针对社区的智能停车管理，在线停车收费'},
        {img: require('../../assets/hardware/2.png'),title:'人脸门禁',text:'面部作为唯一识别标志，刷脸进大门和单元门'},
        {img: require('../../assets/hardware/3.png'),title:'智能快递柜',text:'通过微信存取快递，增加业主粘性，提高服务质量'},
        {img: require('../../assets/hardware/4.png'),title:'智能充电桩',text:'智能充电桩在线缴费，记录可查询，管理方便'},
        {img: require('../../assets/hardware/5.png'),title:'智能摄像头',text:'智能摄像头监控高空抛物，保障人身安全'},
        {img: require('../../assets/hardware/6.png'),title:'智能水电表',text:'利用智能水电表，实现远程抄水表和电表'},
        {img: require('../../assets/hardware/7.png'),title:'蓝牙门禁',text:'蓝牙连接稳定，开门响应快速，安全有保障'},
        {img: require('../../assets/hardware/8.png'),title:'人脸识别摄像机',text:'业主可实时查看监控视频，增加放心指数'}
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .business{
    padding: 4rem 0;
    .content_box{
      width: 72rem;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .info{
        align-items: center;
        display: flex;
        flex-direction: column;
        .qkl_title{
          margin-top: 0.5rem;
        }
        .qkl_p{
          margin-top: 1.5rem;
        }
      }
      .list_box{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 2rem;
        width: 84rem;
        .list{
          width: 19rem;
          height: 20rem;
          // background: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 0 2rem;
          text-align: center;
          margin-top: 2rem;
          &:nth-child(-n+4){
            margin-top: 0;
          }
          .img{
            width: 14rem;
            height: 14rem;
            img{
              width: 100%;
              height: 100%;
              object-fit: scale-down;
            }
          }
          .title{
            color: #202020;
            margin: 0.5rem 0;
          }
          .text{
            height: 6rem;
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .business{
    padding: 8vw 0;
    .content_box{
      width: 90vw;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .info{
        align-items: center;
        display: flex;
        flex-direction: column;
        .qkl_title{
          margin-top: 1vw;
        }
        .qkl_p{
          margin-top: 3vw;
        }
      }
      .list_box{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 4vw;
        width: 90vw;
        .list{
          width: 90vw;
          // background: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          padding: 8vw 0;
          .img{
            width: 90vw;
            // height: 90vw;
            img{
              width: 100%;
              height: 100%;
              object-fit: scale-down;
            }
          }
          .title{
            color: #202020;
            margin: 1vw 0;
          }
          .text{
            margin-top: 2vw;
          }
        }
      }
    }
  }
}
</style>
