<template>
  <div class="about">
    <Banner title="智慧社区" />
    <Business />
    <Intelligen />
    <ManageUse />
  </div>
</template>

<script>
import Banner from '../../src/components/banner/Banner'
import ManageUse from '../components/manage/ManageUse'
import Business from '../components/intelligence/Business'
import Intelligen from '../components/intelligence/Intelligen'


export default {
  name: "Intelligence",
  components: {
    Banner,
    ManageUse,
    Intelligen,
    Business
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .about {
    padding-bottom: 10rem;
    .banner {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .about {
    padding-bottom: 10vw;
    .banner {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .section1 {
      width: 80vw;
      margin: 0 auto;
      padding: 4vw 0;
      .tip{
        text-align: center;
        font-size: 5vw;
      }
      .title {
        text-align: center;
        margin-top: 2vw;
        font-size: 5vw;
        font-weight: bold;
      }
      .content{
        margin-top: 3vw;
        p{
          margin-top: 2vw;
          font-size: 2.5vw;
          text-align: justify;
          line-height: 1.8;
        }
      }
    }
  }
}
</style>
