<template>
  <div class="about">
    <Banner title="物业SCRM" />
    <SCRM />
    <ManageUse />
  </div>
</template>

<script>
import Banner from '../../src/components/banner/Banner'
import SCRM from '../components/SCRM/SCRM'
import ManageUse from '../components/manage/ManageUse'

export default {
  components: {
    Banner,
    ManageUse,
    SCRM
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 992px) {
}
@media screen and (max-width: 992px) {
}
</style>
